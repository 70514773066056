import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from 'layout';
import { useAuthContext } from 'contexts/AuthContext';
import { ROUTE_PATH } from 'utils/route-util';
import Permission from 'components/Sidebar/Permission';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Announcement = lazy(() => import('pages/Announcement'));
const CreateAnnouncement = lazy(() => import('pages/Announcement/CreateAnnounce'));
const Login = lazy(() => import('pages/Login'));

const Employer = lazy(() => import('pages/Employer'));
const Employers = lazy(() => import('pages/Employer/Employers'));
const EmployerInfo = lazy(
  () => import('pages/Employer/Employers/EmployerInfo')
);
const Package = lazy(() => import('pages/Employer/Package'));
const CvDownloaded = lazy(() => import('pages/Employer/CvDownloaded'));

const JobSeeker = lazy(() => import('pages/JobSeeker'));
const JobSeekers = lazy(() => import('pages/JobSeeker/JobSeekers'));
const JobApplied = lazy(() => import('pages/JobSeeker/JobApplied'));
const OfferLetters = lazy(() => import('pages/JobSeeker/OfferLetter'));
const Interviews = lazy(() => import('pages/JobSeeker/Interviews'));
const OnlineInterview = lazy(
  () => import('pages/JobSeeker/Interviews/OnlineInterview')
);

const Job = lazy(() => import('pages/Job'));
const JobDetail = lazy(() => import('pages/Job/JobDetail'));
const EditJob = lazy(() => import('pages/Job/EditJob'));
const CommonIndustry = lazy(() => import('pages/Common/Industry'));
const CommonCategory = lazy(() => import('pages/Common/Category'));
const BloggingPost = lazy(() => import('pages/Blogging/Post'));
const BloggingCategory = lazy(() => import('pages/Blogging/Category'));
const BloggingCreatePost = lazy(
  () => import('pages/Blogging/Post/CusPostForm')
);
const BloggingPostDetail = lazy(() => import('pages/Blogging/Post/PostDetail'));
const LearnMoreCategory = lazy(() => import('pages/LearnMore/Category'));
const LearnMoreVideo = lazy(() => import('pages/LearnMore/Video'));
const LearnMoreCreateVideo = lazy(
  () => import('pages/LearnMore/Video/CusVideoForm')
);
const LearnMoreVideoDetail = lazy(
  () => import('pages/LearnMore/Video/VideoDetail')
);

const UserList = lazy(() => import('pages/UserManagement/UserList'));
const UserProfile = lazy(() => import('pages/UserManagement/Profile'));
const Configuration = lazy(() => import('pages/Configuration'));
const ReportAssessment = lazy(() => import('pages/ReportAssessment'));
const JobSeekerDetail = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail')
);
const JobSeekerProfile = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail/JobSeekerProfile')
);
const JobSeekerInterviewHistory = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail/InterviewHistory')
);
const JobSeekerApplyJobHistory = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail/ApplyJobHistory')
);
const JobSeekerNoteHistory = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail/NoteHistory')
);
const JobSeekerUpdate = lazy(
  () => import('pages/JobSeeker/JobSeekers/JobSeekerDetail/JobSeekerUpdate')
);

export default function AllRoutes() {
  const { authState } = useAuthContext();

  return (
    <Routes>
      <Route
        path={ROUTE_PATH.root}
        element={
          authState.authed ? <Layout /> : <Navigate to={ROUTE_PATH.login} />
        }
      >
        <Route
          path={ROUTE_PATH.root}
          element={<Navigate to={ROUTE_PATH.dashboard} />}
        />
        <Route
          path={ROUTE_PATH.dashboard}
          element={
            <Permission slug='DASHBOARD'>
              <Suspense fallback={null}>
                <Dashboard />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.announcement.root}
          element={
            <Permission slug='EMPLOYER'>
              <Suspense fallback={null}>
                <Announcement />
              </Suspense>
            </Permission>
          }
        >
          <Route
            path={ROUTE_PATH.announcement.root}
            element={<Navigate replace to={ROUTE_PATH.announcement.root} />}
          />
        </Route>
        <Route
          path={ROUTE_PATH.announcement.createAnnouncement}
          element={
            <Permission slug='CREATE_ANNOUNCEMENT'>
              <Suspense fallback={null}>
                <CreateAnnouncement />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.employer.root}
          element={
            <Permission slug='EMPLOYER'>
              <Suspense fallback={null}>
                <Employer />
              </Suspense>
            </Permission>
          }
        >
          <Route
            path={ROUTE_PATH.employer.root}
            element={<Navigate replace to={ROUTE_PATH.employer.list} />}
          />
          <Route
            path={ROUTE_PATH.employer.list}
            element={
              <Permission slug='EMPLOYER'>
                <Suspense fallback={null}>
                  <Employers />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.employer.package}
            element={
              <Permission slug='EMPLOYER'>
                <Suspense fallback={null}>
                  <Package />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.employer.cvDownload}
            element={
              <Permission slug='EMPLOYER'>
                <Suspense fallback={null}>
                  <CvDownloaded />
                </Suspense>
              </Permission>
            }
          />
        </Route>
        <Route
          path={ROUTE_PATH.employer.detail}
          element={
            <Permission slug='EMPLOYER'>
              <Suspense fallback={null}>
                <EmployerInfo />
              </Suspense>
            </Permission>
          }
        />

        <Route
          path={ROUTE_PATH.jobSeeker.root}
          element={
            <Permission slug='JOB_SEEKER'>
              <Suspense fallback={null}>
                <JobSeeker />
              </Suspense>
            </Permission>
          }
        >
          <Route
            path={ROUTE_PATH.jobSeeker.root}
            element={<Navigate replace to={ROUTE_PATH.jobSeeker.jobSeeker} />}
          />
          <Route
            path={ROUTE_PATH.jobSeeker.jobSeeker}
            element={
              <Permission slug='JOB_SEEKERS'>
                <Suspense fallback={null}>
                  <JobSeekers />
                </Suspense>
              </Permission>
            }
          />

          <Route
            path={ROUTE_PATH.jobSeeker.jobApplied}
            element={
              <Permission slug='JOB_APPLIED'>
                <Suspense fallback={null}>
                  <JobApplied />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.interviews}
            element={
              <Permission slug='INTERVIEWS'>
                <Suspense fallback={null}>
                  <Interviews />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.offerLetter}
            element={
              <Permission slug='OFFERLETTER'>
                <Suspense fallback={null}>
                  <OfferLetters />
                </Suspense>
              </Permission>
            }
          />
        </Route>

        <Route
          path={ROUTE_PATH.jobSeeker.jobSeekerDetail}
          element={
            <Permission slug='JOB_SEEKERS'>
              <Suspense fallback={null}>
                <JobSeekerDetail />
              </Suspense>
            </Permission>
          }
        >
          <Route
            path={ROUTE_PATH.jobSeeker.jobSeekerDetail}
            element={
              <Navigate replace to={ROUTE_PATH.jobSeeker.jobSeekerProfile} />
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.jobSeekerProfile}
            element={
              <Permission slug='JOB_SEEKERS'>
                <Suspense fallback={null}>
                  <JobSeekerProfile />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.interviewHistory}
            element={
              <Permission slug='JOB_SEEKERS'>
                <Suspense fallback={null}>
                  <JobSeekerInterviewHistory />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.applyJobHistory}
            element={
              <Permission slug='JOB_SEEKERS'>
                <Suspense fallback={null}>
                  <JobSeekerApplyJobHistory />
                </Suspense>
              </Permission>
            }
          />
          <Route
            path={ROUTE_PATH.jobSeeker.noteHistory}
            element={
              <Permission slug='JOB_SEEKERS'>
                <Suspense fallback={null}>
                  <JobSeekerNoteHistory />
                </Suspense>
              </Permission>
            }
          />
        </Route>
        <Route
          path={ROUTE_PATH.jobSeeker.jobSeekerUpdate}
          element={
            <Permission slug='JOB_SEEKERS'>
              <Suspense fallback={null}>
                <JobSeekerUpdate />
              </Suspense>
            </Permission>
          }
        />

        <Route
          path={ROUTE_PATH.job}
          element={
            <Permission slug='JOB'>
              <Suspense fallback={null}>
                <Job />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.jobDetail}
          element={
            <Permission slug='JOB'>
              <Suspense fallback={null}>
                <JobDetail />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.jobEdit}
          element={
            <Permission slug='JOB'>
              <Suspense fallback={null}>
                <EditJob />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.common.category}
          element={
            <Permission slug='COMMON_CATEGORY'>
              <Suspense fallback={null}>
                <CommonCategory />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.common.industry}
          element={
            <Permission slug='COMMON_INDUSTRY'>
              <Suspense fallback={null}>
                <CommonIndustry />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.blogging.post}
          element={
            <Permission slug='BLOGGING_POST'>
              <Suspense fallback={null}>
                <BloggingPost />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.blogging.category}
          element={
            <Permission slug='BLOGGING_POST'>
              <Suspense fallback={null}>
                <BloggingCategory />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.blogging.createPost}
          element={
            <Permission slug='BLOGGING_POST'>
              <Suspense fallback={null}>
                <BloggingCreatePost />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.blogging.editPost}
          element={
            <Permission slug='BLOGGING_POST'>
              <Suspense fallback={null}>
                <BloggingCreatePost />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.blogging.postDetail}
          element={
            <Permission slug='BLOGGING_POST'>
              <Suspense fallback={null}>
                <BloggingPostDetail />
              </Suspense>
            </Permission>
          }
        />

        <Route
          path={ROUTE_PATH.user.userList}
          element={
            <Permission slug='USER_LIST'>
              <Suspense fallback={null}>
                <UserList />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.learnMore.category}
          element={
            <Permission slug='USER_LIST'>
              <Suspense fallback={null}>
                <LearnMoreCategory />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.user.profile}
          element={
            <Permission slug='PROFILE'>
              <Suspense fallback={null}>
                <UserProfile />
              </Suspense>
            </Permission>
          }
        />

        <Route
          path={ROUTE_PATH.learnMore.video}
          element={
            <Permission slug='LEARNMORE_VIDEO'>
              <Suspense fallback={null}>
                <LearnMoreVideo />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.learnMore.createVideo}
          element={
            <Permission slug='LEARNMORE_VIDEO'>
              <Suspense fallback={null}>
                <LearnMoreCreateVideo />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.learnMore.editVideo}
          element={
            <Permission slug='LEARNMORE_VIDEO'>
              <Suspense fallback={null}>
                <LearnMoreCreateVideo />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.learnMore.videoDetail}
          element={
            <Permission slug='LEARNMORE_VIDEO'>
              <Suspense fallback={null}>
                <LearnMoreVideoDetail />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.assessmentReport}
          element={
            <Permission slug='REPORT_ASSESSMENT'>
              <Suspense fallback={null}>
                <ReportAssessment />
              </Suspense>
            </Permission>
          }
        />
        <Route
          path={ROUTE_PATH.configuation}
          element={
            <Permission slug='CONFIGUATION'>
              <Suspense fallback={null}>
                <Configuration />
              </Suspense>
            </Permission>
          }
        />
      </Route>

      <Route
        path={ROUTE_PATH.videoCall}
        element={
          <Suspense fallback={null}>
            <OnlineInterview />
          </Suspense>
        }
      />

      <Route
        path={ROUTE_PATH.login}
        element={
          authState.authed ? (
            <Navigate to={ROUTE_PATH.root} />
          ) : (
            <Suspense fallback={null}>
              <Login />
            </Suspense>
          )
        }
      />
    </Routes>
  );
}
